import styled from '@grebban/style-system-react';

import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import Text from 'components/Text';
import Button from 'components/Button';
import { ArticlesSlider, type ArticleSelection } from 'components/Article/ArticlesSlider';
import { type TransformHeadingOutput } from 'libs/wordpress/utils/transformHeading';
import { PostsContainerToAlgoliaArticle } from './PostsContainerToAlgoliaArticle';

const Div = styled('div')``;

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const ContentWrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 32px;
`;

const SliderWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export type ArticlesPromotionComponentPropsHeading = TransformHeadingOutput;

export interface PromotionSliderComponentProps extends Record<string, unknown> {
    heading?: ArticlesPromotionComponentPropsHeading;
    button?: any; // TODO: FIX THIS TYPE
    articleSelectionIds: number[];
    articleSelection: ArticleSelection[];
}

export const ArticlesPromotionComponent = ({
    heading,
    button,
    articleSelection,
    articleSelectionIds,
}: PromotionSliderComponentProps) => (
    <Wrapper gap="32px">
        <ContentWrapper
            justifyContent={['unset', null, null, null, 'space-between']}
            marginRight={['12px', null, '16px', null, '40px']}
        >
            {heading && (
                <Wysiwyg
                    data={heading?.text}
                    TextComponent={(props) => (
                        <Text
                            width={['100%', null, null, null, '50%']}
                            maxWidth={['100%', null, null, null, '50%']}
                            textAlign="left"
                            as={heading?.type || 'h3'}
                            color={heading?.color || 'var(--text-color-default)'}
                            typography={[
                                'Content/20_130_0_450',
                                null,
                                'Content/24_130_0_450',
                                null,
                                'Content/40_120_0_500',
                            ]}
                            {...props}
                        />
                    )}
                />
            )}
            {button && (
                <Button
                    key={`${button.url}`}
                    typography="UI/16_100_0_450"
                    theme={button.theme || 'secondary'}
                    to={button.url}
                >
                    {button.children}
                </Button>
            )}
        </ContentWrapper>

        <SliderWrapper gap={['24px', null, '32px']}>
            <PostsContainerToAlgoliaArticle ids={articleSelectionIds}>
                {({ response }) => <ArticlesSlider response={response} articleSelection={articleSelection} />}
            </PostsContainerToAlgoliaArticle>
        </SliderWrapper>
    </Wrapper>
);
