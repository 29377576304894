import React from 'react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { ArticlesPromotionComponent, type PromotionSliderComponentProps } from 'content/ArticlesPromotion';

import { type WordpressHeadingGroup } from 'libs/wordpress/types/contentSystem/groups';
import { type WordpressLinkButton } from 'libs/wordpress/types/contentSystem/elements';
import { transformLinkButton } from 'libs/wordpress/utils/transformButton';
import { transformHeadingGroup } from 'libs/wordpress/utils/transformHeading';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';

// TODO: MOVE these wp types to correct files
export interface WordpressArticleCustomization {
    tag: string;
    // Note: Add more options if implemented in CMS
}

export interface WordpressArticleSelect {
    article: number;
    customize: WordpressArticleCustomization;
    customized: boolean;
}

export interface WordpressArticleSelection {
    articles: {
        article: WordpressArticleSelect;
    }[];
    layout: 'wordpress_articles';
}

export interface WordpressArticlesPromotionBlock {
    settings: Array<'heading' | 'button'>;
    heading?: WordpressHeadingGroup;
    button?: WordpressLinkButton;
    articleSelection: WordpressArticleSelection[];
}

// TODO: Remove Record<string, unknown> when styling is fixed, correctly pass css via rest
export interface ArticlesPromotionBlockProps extends Record<string, unknown> {
    data: WordpressArticlesPromotionBlock;
    layout: 'articles_promotion';
}

const ArticlesPromotionBlock: React.FunctionComponent<ArticlesPromotionBlockProps> = ({ data, layout, ...rest }) => {
    const isMobile = useBreakpoint({ to: 'tablet.sm' });
    const device = isMobile ? 'mobile' : 'desktop';

    const componentData: PromotionSliderComponentProps = {
        articleSelection: [],
        articleSelectionIds: [],
    };

    // Heading
    if (data?.settings.includes('heading') && data?.heading) {
        componentData.heading = transformHeadingGroup(data.heading, device);
    }

    // Link Button
    if (data?.settings.includes('button') && data?.button) {
        const tButton = transformLinkButton(data.button);
        componentData.button = {
            url: tButton.link,
            children: (
                <>
                    {tButton.label}
                    <ArrowIcon />
                </>
            ),
            theme: tButton.theme || 'primary',
        };
    }

    // Article Selection
    if (data?.articleSelection) {
        const ids: number[] = data.articleSelection[0]?.articles.map((article) => article.article.article);
        componentData.articleSelectionIds = ids;
        data.articleSelection[0]?.articles.map((article) => {
            componentData.articleSelection.push({
                id: article.article.article,
                tag: article.article.customize.tag,
            });
        });
    }

    return <ArticlesPromotionComponent {...componentData} {...rest} />;
};

export default ArticlesPromotionBlock;
