import { type PropsWithChildren, useEffect, useState, useRef } from 'react';
import styled from '@grebban/style-system-react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { inServer } from '@sportson/core-web/constants';

import TouchableSlider from '@sportson/core-web/components/sliders/TouchableSlider';
import Button from 'components/Button';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';
import { ArticleCard, type ArticleCardProps } from 'components/Article/ArticleCard';

const SliderFooter = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    width: 100%;
`;

const SliderButtonWrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 24px;
`;

const SlideSteps = styled('div')`
    height: 2px;
    width: 100%;
    border-radius: var(--corner-radius-round, 4px);
    background: var(--color-neutrals-200);
    overflow: hidden;
`;

const SliderStep = styled('div')`
    height: 2px;
    border-radius: var(--corner-radius-round, 4px);
    background: var(--color-base-black);

    transition: width var(--transition-primary-slow);
`;

const ClickWrapper = styled('div')`
    -webkit-overflow-scrolling: touch;

    // This will disable click
    &.disable-pointer-events * {
        pointer-events: none !important;
    }
`;

export interface ArticleSelection {
    id: number;
    tag?: string;
}

export type ArticlesSliderProps = PropsWithChildren<{
    response: ArticleCardProps[];
    articleSelection: ArticleSelection[];
}>;

type BreakpointType = 'default' | 'mobile' | 'desktop';

export const ArticlesSlider = ({ response, articleSelection, ...rest }: ArticlesSliderProps) => {
    const { t } = useTranslation();
    const isDesktop = useBreakpoint({ from: 'tablet.lg' });
    const isMobile = useBreakpoint({ to: 'tablet.sm' });

    const sliderRef = useRef<HTMLDivElement>(null);
    const stepsRef = useRef<HTMLDivElement>(null);

    const [slideIndex, setSlideIndex] = useState(0);
    const [sliderPos, setSliderPos] = useState('start');
    const [stepWidth, setStepWidth] = useState(0);

    const numberOfChildren = articleSelection?.length;

    const getSlidesPerView = (isMobile: boolean, isDesktop: boolean): number => {
        const slidesPerViewMapping: Record<BreakpointType, number> = {
            mobile: 1.1,
            desktop: 2.2,
            default: 2.2,
        };

        return slidesPerViewMapping[isMobile ? 'mobile' : isDesktop ? 'desktop' : 'default'];
    };

    const slidesPerView = getSlidesPerView(isMobile, isDesktop);

    const slideTo = (index: number) => {
        if (index < 0) {
            index = 0;
        }
        requestAnimationFrame(() => {
            setSlideIndex(index + slidesPerView > numberOfChildren ? numberOfChildren - slidesPerView : index);
        });
    };

    const animateStep = (index: number) => {
        if (!stepsRef.current) return;
        index += 1;
        const totalSteps = numberOfChildren - (slidesPerView - 1);
        const percentagePerStep = stepsRef.current.offsetWidth / totalSteps;

        setStepWidth(Math.round(percentagePerStep * index));
    };

    const slidePrev = () => {
        slideTo(slideIndex - 1);
        animateStep(slideIndex - 1);
    };

    const slideNext = () => {
        slideTo(slideIndex + 1);
        animateStep(slideIndex + 1);
    };

    useEffect(() => {
        if (slideIndex === 0) {
            setSliderPos('start');
        } else if (slideIndex + slidesPerView >= numberOfChildren) {
            setSliderPos('end');
        } else {
            setSliderPos('between');
        }

        // NOTE: Fixes bug where first slide is not reset properly
        if (slideIndex < 0.25) {
            setSlideIndex(0);
        }
    }, [slideIndex, slidesPerView, numberOfChildren]);

    useEffect(() => {
        if (!stepsRef.current) return;

        const indicatorWidth = stepsRef.current?.offsetWidth;
        // if (numberOfChildren < 6) {
        //     setStepWidth(indicatorWidth * 0.5);
        // } else {
        const totalSteps = numberOfChildren - slidesPerView;
        const percentagePerStep = indicatorWidth / totalSteps;
        setStepWidth(Math.round(percentagePerStep));
        // }
    }, [stepsRef.current]);

    useEffect(() => {
        slideTo(0);
        if (isDesktop) {
            animateStep(0);
        }
    }, [isDesktop, isMobile]);

    useEffect(() => {
        // if current slideIndex is less than new slideIndex, animate to the right else to the left
        if (slideIndex > 0) {
            animateStep(slideIndex);
        } else {
            animateStep(0);
        }
    }, [slideIndex]);

    useEffect(() => {
        const sliderElement = sliderRef.current;

        const handleMouseDown = (e: MouseEvent) => {
            // Only start the drag if the target is within the slider to prevent
            if (sliderElement?.contains(e.target as Node)) {
                e.preventDefault();
                document.addEventListener('mouseup', handleMouseUp);

                // If the user makes a quick click it will work like usual
                // But if the user holds the click for more than 100ms it will enable drag
                setTouchTimeout();
            }
        };

        // Add listeners used by drag functionality
        sliderElement?.addEventListener('mousedown', handleMouseDown);

        return () => {
            sliderElement?.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    const [state, setState] = useState({ dragging: false });
    let touchTimeout;

    const setTouchTimeout = () => {
        touchTimeout = setTimeout(() => {
            document.addEventListener('mousemove', handleMouseMove);
        }, 100);
    };

    const clearTouchTimeout = () => {
        clearTimeout(touchTimeout);
    };

    const handleMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        startDrag(e);
    };

    const startDrag = (e: MouseEvent) => {
        e.preventDefault();
        setState({ dragging: true });
    };

    const handleMouseUp = (e: MouseEvent) => {
        e.preventDefault();
        clearTouchTimeout();
        endDrag();
    };

    const endDrag = () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
        setState({ dragging: false });
    };

    if (isDesktop) {
        return (
            <>
                <div ref={sliderRef}>
                    <TouchableSlider
                        paddingRight="40px"
                        width="100%"
                        overflow="unset"
                        spaceBetween="24px"
                        slidesPerView={slidesPerView}
                        activeSlideIndex={slideIndex}
                        onSlideChange={setSlideIndex}
                        {...rest}
                    >
                        {articleSelection.map((article: any) => {
                            const { tag } = article;
                            return response.map(
                                (data: any) =>
                                    data.external_id === article.id && (
                                        <ClickWrapper className={state.dragging ? 'disable-pointer-events' : undefined}>
                                            <ArticleCard key={data.title} article={data} customTag={tag} />
                                        </ClickWrapper>
                                    ),
                            );
                        })}
                    </TouchableSlider>
                </div>
                <SliderFooter
                    display={numberOfChildren < 4 && 'none'}
                    paddingRight={['12px', null, '16px', null, '40px']}
                >
                    <SlideSteps ref={stepsRef}>
                        <SliderStep width={`${stepWidth}px`} />
                    </SlideSteps>
                    <SliderButtonWrapper>
                        <Button
                            aria-label={t('pdp.gallery.previous_image')}
                            size="xsmall"
                            theme="secondary"
                            onClick={slidePrev}
                            disabled={sliderPos === 'start'}
                        >
                            <ArrowIcon direction="left" />
                        </Button>
                        <Button
                            aria-label={t('pdp.gallery.next_image')}
                            size="xsmall"
                            theme="secondary"
                            onClick={slideNext}
                            disabled={sliderPos === 'end'}
                        >
                            <ArrowIcon />
                        </Button>
                    </SliderButtonWrapper>
                </SliderFooter>
            </>
        );
    }

    return (
        <div ref={sliderRef}>
            <TouchableSlider
                paddingRight={['12px', null, '16px']}
                width="100%"
                overflow="unset"
                spaceBetween="16px"
                slidesPerView={slidesPerView}
                activeSlideIndex={slideIndex}
                onSlideChange={setSlideIndex}
                {...rest}
            >
                {articleSelection.map((article: any) => {
                    const { tag } = article;
                    return response.map(
                        (data: any) =>
                            data.external_id === article.id && (
                                <ArticleCard key={data.title} article={data} customTag={tag} />
                            ),
                    );
                })}
            </TouchableSlider>
        </div>
    );
};
