import AspectWrapper from '@sportson/core-web/components/AspectWrapper';
import FullWidth from '@sportson/core-web/App/layouts/FullWidth';
import ArticlesPromotionBlock, {
    type WordpressArticlesPromotionBlock,
} from 'libs/wordpress/content/Blocks/ArticlesPromotion';
import { type WordpressStandardModuleSettings } from '../../types/contentSystem/groups';
import { transformBackgroundColor } from '../../utils/transformBackground';

export interface ArticlesPromotionProps extends Record<string, unknown> {
    data: {
        data: WordpressArticlesPromotionBlock;
        moduleSettings: WordpressStandardModuleSettings;
    };
    layout: 'articles_promotion';
    isHero?: boolean;
}

const ArticlesPromotionModule = ({ data, isHero, layout, ...rest }: ArticlesPromotionProps) => {
    const { moduleSettings, data: blockData } = data;
    const { backgroundColor } = moduleSettings;

    // console.log('ArticlesPromotionModule', data, isHero, layout, rest);
    // @TODO Implement background, spacing, ratio and visibility
    const moduleBgColor = transformBackgroundColor(backgroundColor).backgroundColor;
    return (
        <FullWidth
            gridColumn={isHero ? 'full-width' : ''}
            mt={isHero ? '' : 'var(--module-margin-top-default)'}
            mb={isHero ? 'var(--module-margin-bottom-small)' : 'var(--module-margin-bottom-default)'}
            p={['40px 0 40px 12px', null, '40px 0 40px 16px', null, '64px 0 64px 40px']}
            backgroundColor={moduleBgColor || 'var(--color-transparent)'}
        >
            <ArticlesPromotionBlock data={blockData} layout={layout} {...rest} />
        </FullWidth>
    );
};

export default ArticlesPromotionModule;
