import { useEffect, useState } from 'react';
import styled from 'libs/styled';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import decodeHTMLEntities from '@sportson/core-web/utils/decodeHTMLEntities';

import Link from '@sportson/core-web/components/Link';
import Text from 'components/Text';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { type AlgoliaArticle } from './types';

const CardImageLink = styled(Link)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ImageWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    aspect-ratio: 3 / 2;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        mix-blend-mode: multiply;
    }
`;

const TagWrapper = styled('div')`
    display: flex;
    padding: 6px 8px 4px 8px;
    align-items: flex-start;
    gap: 8px;
    z-index: var(--zindex-middleground);
    position: absolute;
    top: 12px;
    left: 12px;

    border-radius: var(--corner-radius-round, 4px);
    background: var(--color-transparency-white-85);
    backdrop-filter: blur(6px);
`;

const TextWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export interface ArticleCardProps extends Record<string, unknown> {
    article: AlgoliaArticle;
    gridColumn?: string;
    customTag?: string;
}

export interface CardDataProps {
    title: string;
    summary: string[];
    image: {
        url: string;
        alt: string;
    } | null;
    cardTag: string;
}

export const ArticleCard = ({ article, gridColumn = '', customTag, ...rest }: ArticleCardProps) => {
    const isMobile = useBreakpoint({ to: 'tablet.sm' });
    const {
        url,
        title: articleTitle,
        hero_content: articleContent,
        primary_category: primaryCategory,
        category,
    } = article;

    const [cardData, setCardData] = useState<CardDataProps>({
        title: '',
        summary: [],
        image: {
            url: '',
            alt: '',
        },
        cardTag: '',
    });

    useEffect(() => {
        if (articleContent) {
            const altTitle = articleContent?.article_summary?.alternative_title ?? '';
            const summary = articleContent?.article_summary?.summary;
            const backgroundImage = articleContent?.article_image?.background_image ?? {};

            setCardData({
                title: altTitle || articleTitle,
                summary,
                image:
                    isMobile && backgroundImage.mobile_background_image.image
                        ? backgroundImage.mobile_background_image.image
                        : backgroundImage.desktop_background_image
                          ? backgroundImage.desktop_background_image.image
                          : null,
                cardTag: customTag || primaryCategory || category[0],
            });
        }
    }, [article]);

    const { title, summary, image, cardTag } = cardData;
    return (
        <CardImageLink to={url} gridColumn={gridColumn} aspectRatio={['307/385', null, null, null, '58/51']} {...rest}>
            <ImageWrapper>
                {cardTag && (
                    <TagWrapper>
                        <Text typography="UI/14_100_0_450_uppercase" color="var(--color-base-black)">
                            {cardTag}
                        </Text>
                    </TagWrapper>
                )}

                {/* TODO: NOTE: Implement proper image component */}
                {image?.url && <img src={image.url} alt={image.alt} />}
            </ImageWrapper>
            <TextWrapper>
                {title && (
                    <Text typography={['Content/20_130_0_450', null, null, null, 'Content/24_130_0_450']} as="h2">
                        {decodeHTMLEntities(title)}
                    </Text>
                )}
                {summary && (
                    <Wysiwyg
                        data={summary}
                        TextComponent={(props) => <Text typography="Content/16_150_0_350" as="p" {...props} />}
                    />
                )}
            </TextWrapper>
        </CardImageLink>
    );
};
